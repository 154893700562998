import { FunctionComponent } from 'react'
import { Link } from 'gatsby'

const WelcomePage: FunctionComponent = () => {
  return (
    <main>
      <title>Welcome Page</title>
      <h1>This is the welcome page.</h1>
      <Link to="/">Home Page</Link>
    </main>
  )
}

export default WelcomePage
